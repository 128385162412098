<template>
  <div id="app" class="flex min-h-screen flex-col overflow-x-hidden bg-neutral-25 lg:ml-72">
    <div
      v-show="sidebarOpen && !largerThanLg"
      id="overlay"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 h-full w-full bg-black/70"
    ></div>
    <base-layout-header v-if="!isBoxLayout" />
    <base-layout-sidebar v-show="sidebarOpen || largerThanLg" />
    <main :class="isBoxLayout ? 'grow' : 'grow space-y-4 p-4 md:space-y-8 md:p-8'">
      <slot v-if="isBoxLayout"></slot>
      <section v-else class="md:container md:mx-auto"><slot></slot></section>
    </main>

    <base-layout-footer />
    <controller-toast />
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useLayoutStore } from '~/stores/layout';

const layoutStore = useLayoutStore();

const { sidebarOpen } = storeToRefs(layoutStore);

const breakpoints = useBreakpoints(breakpointsTailwind);
const largerThanLg = breakpoints.greaterOrEqual('lg');

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (sidebarOpen.value && !largerThanLg.value) {
        return 'overflow-y-hidden';
      }
      return '';
    }),
  },
});

const route = useRoute();
const { t: $t } = useI18n();

useSeoMeta({
  title: () => {
    if (route.meta.title) {
      return `${$t(route.meta.title as string)} » raidboxes®`;
    }
    return 'raidboxes®';
  },
});

defineProps({
  isBoxLayout: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
});
</script>
