<template>
  <base-gravatar-wrapper #default="slotProps" :gravatar-url="gravatarUrl">
    <rb-sidebar
      :on-close-sidebar="() => layoutStore.toggleSidebar()"
      :close-button-alt-text="$t('general.button.close')"
      :menu-dropdown-icon-alt-text="$t('sidebar.profile.dropdownMenu.altText')"
      :gravatar-url="slotProps.hasGravatar ? gravatarUrl : ''"
      :avatar-initials="initials"
      :avatar-supporting-text="profile.email"
      :name="name"
      :avatar-alt-text="$t('header.profile.altText', { name: name })"
      :on-logo-click="() => navigateTo('/')"
      :on-logout-click="() => authStore.logout()"
    >
      <rb-button
        v-feature="'BoxPages'"
        class="mb-6 inline-flex lg:hidden"
        hierarchy="secondary"
        size="sm"
        @click="
          () => {
            layoutStore.closeSidebar();
            navigateTo('/boxes/create-new');
          }
        "
      >
        {{ $t('header.button.create_new_box') }}
      </rb-button>
      <rb-sidebar-section :name="$t('sidebar.category.general')">
        <base-layout-navigation-button name="Home" icon="home" />
        <div v-feature="'BoxPages'">
          <base-layout-navigation-button
            name="Dashboard"
            icon="bar-chart"
            to="/dashboard"
            has-sub-menu
          >
            <template #sub-menu>
              <base-layout-navigation-button name="Boxes" to="/boxes" />
            </template>
          </base-layout-navigation-button>
        </div>
      </rb-sidebar-section>
      <rb-sidebar-section :name="$t('sidebar.category.orders')">
        <base-layout-navigation-button
          name="Invoices"
          icon="clock-fast-forward"
          to="/invoices"
          :driver-settings="{
            index: 9,
            popover: {
              description: $t('startpage.dashboardTour.downloadInvoice.step1'),
              nextBtnText: $t('startpage.dashboardTour.continue'),
              showButtons: ['next'],
              onNextClick: () => navigateTo('/invoices?startTour=true'),
            },
          }"
        />
        <base-layout-navigation-button name="Subscriptions" icon="layers" to="/subscriptions" />
        <div v-feature="'DataSync'">
          <base-layout-navigation-button
            name="Payment methods"
            icon="pie-chart"
            to="/payment-methods"
          />
        </div>
      </rb-sidebar-section>
      <template #menu-items-bottom>
        <rb-sidebar-section>
          <div id="beamer-trigger">
            <rb-navigation-button
              icon-name="announcement"
              :icon-alt-text="$t('sidebar.menu_item.nems.iconAltText')"
            >
              {{ $t('sidebar.menu_item.news') }}
            </rb-navigation-button>
          </div>
          <base-layout-navigation-button
            :driver-settings="{
              index: 5,
              popover: {
                description: $t('startpage.dashboardTour.addSSHKey.step1'),
                nextBtnText: $t('startpage.dashboardTour.continue'),
                showButtons: ['next'],
                onNextClick: () => navigateTo('/user/security?startTour=true'),
              },
            }"
            name="Security"
            icon="settings"
            to="/user/security"
          />
          <base-layout-navigation-button
            :driver-settings="{
              index: 15,
              popover: {
                description: $t('startpage.dashboardTour.updateProfile.step1'),
                nextBtnText: $t('startpage.dashboardTour.continue'),
                showButtons: ['next'],
                onNextClick: () => navigateTo('/user/profile?startTour=true'),
              },
            }"
            name="User profile"
            icon="user"
            to="/user/profile"
          />
        </rb-sidebar-section>
      </template>
      <template #title="{ titleClasses }">
        <rb-link
          v-if="name.trim() === ''"
          size="sm"
          type="neutral"
          trailing-icon-name="plus"
          href="/user/profile"
          @click.prevent="navigateTo('/user/profile')"
        >
          {{ $t('sidebar.user_area.add_name') }}
        </rb-link>
        <p :class="titleClasses">{{ name }}</p>
      </template>
    </rb-sidebar></base-gravatar-wrapper
  >
</template>

<script setup lang="ts">
const layoutStore = useLayoutStore();

const userStore = useUserStore();
const { name, profile, gravatarUrl, initials } = storeToRefs(userStore);

const authStore = useAuthStore();
</script>

<script lang="ts">
export default {
  name: 'BaseSidebar',
};
</script>
<style lang="css">
#lang-switch-sidebar > div > button {
  width: 100%;
}

#beamer-trigger .beamer_icon.active,
#beamer-trigger #beamerIcon.active {
  background-color: theme('colors.info.50') !important;
  color: theme('colors.info.700');
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: theme('fontSize.xs');
  right: 12px;
  transform: translateY(50%);
}
</style>
